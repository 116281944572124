import React, {useEffect} from "react";
import svgPanZoom from 'svg-pan-zoom';
import mermaid from "mermaid";

export interface MermaidProps {
    text: string;
    height: number;
}

export const OurMermaid: React.FC<MermaidProps> = ({text, height}) => {
    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        mermaid.initialize({
            startOnLoad: true,
            securityLevel: "loose",
            theme: "dark",
            logLevel: "info",
            maxTextSize: 500000
        });
    });

    useEffect(() => {
        if (ref.current && text !== "") {
            // @ts-ignore
            mermaid.render("preview", text).then(({ svg }) => {
                if (ref.current == null) {
                    return;
                }

                ref.current.innerHTML = svg;

                setTimeout(() => {
                    const svg = ref.current?.querySelector("svg");
                    if (!svg) {
                        return;
                    }

                    svg.setAttribute('height', height + 'px');
                    // @ts-ignore
                    svg.style.maxWidth = null;
                    svgPanZoom("#preview");
                }, 100);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    return <div key="preview" ref={ref}/>;
};
