import React from 'react';
import {PanelProps} from '@grafana/data';
import {SimpleOptions} from 'types';
import {OurMermaid, MermaidProps} from "./OurMermaid";

interface Props extends PanelProps<SimpleOptions> {
}

export const SimplePanel: React.FC<Props> = ({data, height}) => {
    if (data.series.length === 0 || data.series[0].fields.length === 0 || data.series[0].fields[0].values.toArray().length === 0) {
        return (<div>No data</div>);
    }

    const mermaidString = data.series[0].fields[0].values.toArray()[0];

    const mermaidProps: MermaidProps = {
        text: mermaidString,
        height: height
    };

    return (
        <div>
            <OurMermaid {...mermaidProps} />
        </div>
    );
};
